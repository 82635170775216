.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font: inherit;

  img {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 1rem;
  }

  @media (max-width: 510px) {
    background-color: var(--primary-foreground);
    img {
      margin-bottom: 0.5rem;
    }
  }

  .form_container {
    background-color: var(--muted);
    padding: 1.5rem;
    border-radius: 1rem;
    max-width: 450px;
    width: 100%;
    @media (max-width: 510px) {
      border-radius: 0;
      background-color: var(--primary-foreground);
    }
    label,
    input,
    button {
      display: block;
      margin-bottom: 10px;
    }

    label {
      margin: 0;
      padding: 0;
      display: block;
      font-size: 1rem;
      color: var(--foreground);
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    input {
      border: 1px solid var(--border);
      border-radius: 5px;
      width: 100%;
      display: block;
      font: inherit;
      margin-bottom: 1rem;
      box-shadow: -5px 5px 5px 0px var(--foreground);
      padding: 0.75rem 0.5rem;
    }

    button {
      background-color: var(--primary);
      color: var(--primary-foreground);
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      width: 100%;
      font: inherit;
      letter-spacing: 1px;
      font-weight: 500;
      box-shadow: -5px 5px 5px 0px var(--foreground);
      transition: all 0.1s ease-in-out;
      margin: 1rem 0;
      padding: 0.75rem 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
