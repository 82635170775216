@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #333333;
  --card: #ffffff;
  --card-foreground: #333333;
  --popover: #ffffff;
  --popover-foreground: #333333;
  --primary: #f97316;
  --primary-foreground: #ffffff;
  --secondary: #cccccc;
  --secondary-foreground: #333333;
  --muted: #f0f0f0;
  --muted-foreground: #333333;
  --border: #e0e0e0;
  --input: #ffffff;
  --ring: #f97316;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  max-width: 1000px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font: inherit;
}

a {
  text-decoration: none;
  color: var(--foreground);
  &:hover {
    color: var(--primary);
  }
}

li {
  list-style: none;
}

input {
  outline: none;
  font-size: 16px;
}

.disabled-btn {
  background: var(--muted) !important;
  box-shadow: none !important;
  color: white !important;
  pointer-events: none;
}

.cont {
  max-width: 1200px;
  margin: 0 auto;
}

.icon_button_custom {
  background: var(--primary-foreground);
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 2rem;
    font-weight: bold;
    color: var(--secondary-foreground);
  }
  &:nth-child(3) {
    background: var(--primary);
    svg {
      color: var(--primary-foreground);
    }
  }
  &:first-child {
    background: var(--primary);
    svg {
      color: var(--primary-foreground);
    }
  }
  &:last-child {
    background: var(--primary);
    svg {
      color: var(--primary-foreground);
    }
  }
}

.blurred-bg {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.overflow-hidden--imp {
  overflow: hidden !important;
}
