.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(209, 213, 219, 0.3);
  padding: 0 0.75rem;
  z-index: 1000;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    h2 {
      font-weight: 600;
      font-size: 1.5rem;
      color: var(--secondary-foreground);
    }
  }
}
