.footer {
  background-color: var(--secondary-foreground);
  height: 60px;
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(209, 213, 219, 0.3);
  .footerMainBtn {
    background: var(--secondary);
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 1.5rem;
      font-weight: bold;
      color: var(--secondary-foreground);
    }
    &:nth-child(3) {
      background: var(--primary);
      svg {
        color: var(--primary-foreground);
      }
    }
    &:first-child {
      background: var(--primary);
      svg {
        color: var(--primary-foreground);
      }
    }
    &:last-child {
      background: var(--primary);
      svg {
        color: var(--primary-foreground);
      }
    }
  }
}
